import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["permission", "start", "stop", "recording", "animation", "audio", "file", "save"]

  async permission() {
    this.permissionTarget.classList.toggle("d-none", true)
    this.startTarget.classList.toggle("d-none", false)

    const stream = await navigator.mediaDevices.getUserMedia({ audio: true })

    this.recorder = new MediaRecorder(stream)
    this.recorder.addEventListener("dataavailable", e => {
      this.chunks.push(e.data)
      if (this.recorder.state === "inactive") {
        this.setAudio()
      }
    })

    const audioContext = new AudioContext()
    this.analyser = audioContext.createAnalyser()
    this.analyser.fftSize = 256
    this.dataArray = new Uint8Array(this.analyser.frequencyBinCount)
    const source = audioContext.createMediaStreamSource(stream)
    source.connect(this.analyser)
  }

  start() {
    this.chunks = []
    this.saveTarget.classList.toggle("d-none", true)
    this.stopTarget.classList.toggle("d-none", false)
    this.startTarget.classList.toggle("d-none", true)
    this.audioTarget.classList.toggle("d-none", true)
    this.audioTarget.src = ''
    this.recordingTarget.classList.toggle("d-none", false)

    this.recorder.start()
    requestAnimationFrame(() => this.animateVolume())
  }

  stop() {
    this.stopTarget.classList.toggle("d-none", true)
    this.startTarget.classList.toggle("d-none", false)
    this.audioTarget.classList.toggle("d-none", false)
    this.recordingTarget.classList.toggle("d-none", true)

    this.recorder.stop()
  }

  async setAudio() {
    const blob = new Blob(this.chunks, { type: "audio/ogg" })
    const audioUrl = URL.createObjectURL(blob)
    this.audioTarget.src = audioUrl
    this.audioTarget.load()
    this.audioTarget.classList.toggle("d-none", false)

    // Only reasonable way to create a FileList to set the file input. See: https://stackoverflow.com/a/56447852/5828035
    const list = new DataTransfer()
    list.items.add(new File([blob], "audio.ogg", { type: "audio/ogg" }))
    this.fileTarget.files = list.files

    this.saveTarget.classList.toggle("d-none", false)
  }

  animateVolume() {
    this.analyser.getByteFrequencyData(this.dataArray)

    const sum = this.dataArray.reduce((a, b) => a + b, 0)
    const averageVolume = sum / this.dataArray.length
    const newScale = Math.min(averageVolume / 32, 1) + 1
    this.animationTarget.style.transform = `scale(${newScale})`

    // Continue the animation loop if recording is active
    if (this.recorder && this.recorder.state === 'recording') {
      requestAnimationFrame(() => this.animateVolume())
    }
  }
}
