import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "selector", "input" ]

    click() {
        if (!this.hasSelectorTarget) return

        this.selectorTarget.checked = true
    }

    reset() {
        if (!this.hasSelectorTarget) return

        this.selectorTarget.checked = false
        this.inputTarget.value = ""
    }
}
