import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['input', 'error']
  static values = { minLength: Number }

  connect() {
    this.inputTarget.addEventListener('input', () => {
      this.errorTarget.style.display = this.inputTarget.value.length < this.minLengthValue ? 'block' : 'none'
    })
  }
}
