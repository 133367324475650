import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    document.addEventListener('turbo:render', () => {
      const firstError = document.querySelector('.field_with_errors')
      // Default behavior is to scroll to the top of the page, which is called after this.
      // So we need to wait for the next frame to scroll to the first error.
      window.requestAnimationFrame(() => {
        firstError?.scrollIntoView({ behavior: 'smooth', block: 'center' })
      })
    })
  }
}
