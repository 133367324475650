import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['page']

    initialize() {
        this.index = 0
        this.showCurrentPage()
    }

    next() {
        this.index++
        this.showCurrentPage()
    }

    previous() {
        this.index--
        this.showCurrentPage()
    }

    showCurrentPage() {
        this.pageTargets.forEach((page, i) => {
            page.classList.toggle('show', i === this.index)
            page.classList.toggle('active', i === this.index)
        })
    }
}
